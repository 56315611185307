<template>
  <div>

    <PageTitle page-title="Page Not Found" />

    <!--Error-404-->
<section class="error_404 section-padding">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-sm-5">
        <div class="error_text_m">
          <h2>4<span>0</span>4</h2>
          <div class="background_icon"><i class="fa fa-road" aria-hidden="true"></i></div>
        </div>
      </div>
      <div class="col-md-6 col-sm-7">
        <div class="not_found_msg">
          <div class="error_icon"> <i class="fa fa-smile-o" aria-hidden="true"></i> </div>
          <div class="error_msg_div">
            <h3>Oops, <span>Page can't be found</span></h3>
            <p>If you were searching for a vehicle please visit the <a href="/pre-owned">Pre-Owned Vehicles </a> page.</p>
            <a href="/" class="btn">Go to Homepage <span class="angle_arrow"><i class="fa fa-angle-right" aria-hidden="true"></i></span></a> </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- /Error-404--> 
  </div>
</template>

<script>
export default {
  name: 'Contact',
  components: {
    PageTitle: () => import('../components/PageTitle'),
  },
}
</script>

<style scoped>
.page-header {
  background-image: url('../../public/assets/images/midland-auto-sales-contact-us-banner.jpg');
}
</style>
